import {CANVAS_SCALE} from './LANDSCAPE';

export const TINY_BOOK_HEIGHT       = 40;
export const TINY_BOOK_WIDTH        = 240;
export const TINY_BOOK_BASE_LEFT    = 3750;
export const TINY_BOOK_BASE_BOTTOM  = 4300;

export const LARGE_BASE_BOOK_HEIGHT = CANVAS_SCALE * 22;
export const LARGE_BASE_BOOK_WIDTH  = CANVAS_SCALE * 36;
export const LARGE_BOOK_BASE_LEFT   = CANVAS_SCALE * 2500;
export const LARGE_BOOK_BASE_BOTTOM = CANVAS_SCALE * 4810;

export const LARGE_BOOK_PADDING_PART = 0.15;