export default [
  'java.png',
  'c.png',
  'csharp.png',
  'net.png',
  'typescript.png',
  'javascript.png',
  'nodejs.png',
  'react.png',
  'redux.png',
  'git.png',
  'graphql.png',
  'python.png',
  'ruby.png',
  'sql.png',
  'sass.png',
  'css.png',
  'html.png',
  'photoshop.png',
  'figma.png',
  'illustrator.png',
  'indesign.png',
  'grasshopper.png',
  'rhino.png',
  'sketchup.png',
  'unreal.png'
];