export default {
  air: [
    'fly',
    'bat',
    'bat-2',
    'wazeba-white'
  ],
  ground: [
    'sheep',
    'giraffe',
    'gezichtje',
    'wazeba-black'
  ]
};